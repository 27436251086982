import React from "react";
import ReactGA from "react-ga";
import { graphql, navigate } from "gatsby";
import Layout from "../components/Layout";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { S3_URL } from "../components/config";
import Helmet from "react-helmet";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { navigateTo } from "gatsby-link";

class CertificatePage extends React.Component {
  constructor(props) {
    super(props);
    this.score = props.pageContext.score;
    this.state = {
      copied: false,
      image_name: `${this.score}.jpg`
    };

    this.url = `https://mittklimatlofte.se/certificate/${this.score}`;
    this.content = this.props.data.markdownRemark.frontmatter;
    this.showCopiedMessage = this.showCopiedMessage.bind(this);
    
    let redirect_to_home = false;
    if(props.location.href){
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      
      if(props.location.href.indexOf("fbclid") !== -1 || (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)){
        redirect_to_home = true
      }
      if (window){
        if (!window.localStorage.getItem('quiz_started') && redirect_to_home){
          navigateTo("/")
        }else{
          window.localStorage.clear()
        }
      }
      
    }
    
  
        
    ReactGA.initialize("UA-131134248-1");
    ReactGA.pageview(`/certificate/${this.score}`);
  }

  showCopiedMessage() {
    setTimeout(
      function(self) {
        self.setState({ copied: false });
      },
      1000,
      this
    );
    return (
      <span>
        <strong>{this.content.copy_to_clipboard_tooltip}</strong>
      </span>
    );
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>{this.content.meta_title}</title>
          <meta name="robots" content="noindex" />
          <meta property="og:title" content={this.content.og_title} />
     
          <meta property="og:image" content={S3_URL + this.state.image_name} />
          <meta property="og:url" content={this.url} />
          <meta name="twitter:title" content={this.content.twitter_title} />
         
          <meta name="twitter:image" content={S3_URL + this.state.image_name} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <section className="content-section align-center-flex certificate-page">
          <div className="container col-8">
            <div className="result-image">
              <img
                src={S3_URL + this.state.image_name}
                alt="Certificate"
                className="certificate"
              />
              <div className="send-to-social">
                <a href="#">
                  <FacebookShareButton
                    url={this.url}
                    quote="Mitt klimatlöfte!"
                    className="button"
                  >
                    <img src="/img/icon-fb.svg" alt="Img" />
                  </FacebookShareButton>
                </a>{" "}
                {/* <a href="#">
                        <TwitterShareButton url={this.url}
                            quote="Mitt klimatlöfte!"
                            className="button" >
                            <img src="/img/icon-tw.svg" alt="Img" />
                        </TwitterShareButton>
                        </a> */}
              </div>
              <hr />
            </div>
            <h1>{this.content.header}</h1>
            <h2>
              {this.content.subheader1} {this.score} {this.content.subheader2}{" "}
              <br /> {this.content.subheader3}
            </h2>
            <form className="copy-url flexbox">
              <input
                type="url"
                name="url"
                value={this.url}
                className="basic-input"
              />
              <CopyToClipboard
                text={this.url}
                onCopy={() => {
                  this.setState({ copied: true });
                }}
              >
                <button type="button" className="cta">
                  <div
                    style={{
                      position: "absolute",
                      marginLeft: "150px",
                      color: "black"
                    }}
                  >
                    {this.state.copied ? this.showCopiedMessage() : null}
                  </div>
                  {this.content.button_text}
                </button>
              </CopyToClipboard>
            </form>
          </div>
        </section>
      </Layout>
    );
  }
}

export default CertificatePage;

export const certificatePageQuery = graphql`
  query CertificatePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header
        subheader1
        subheader2
        subheader3
        copy_to_clipboard_tooltip
        button_text
        meta_title
        og_title
        twitter_title        
      }
    }
  }
`;
